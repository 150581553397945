import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import cloudinaryString from "../helpers/cloudinaryString"
import useStories from "../hooks/useStories"
import MultipleLinksSelector from "../components/Links/MultipleLinksSelector"

export default function About({ data }) {
  const { getStoryByTag } = useStories()

  const about1 = getStoryByTag("about1")
  const about2 = getStoryByTag("about2")
  const about3 = getStoryByTag("about3")
  const about4 = getStoryByTag("about4")
  const about5 = getStoryByTag("about5")
  const about6 = getStoryByTag("about6")
  const about7 = getStoryByTag("about7")
  const about8 = getStoryByTag("about8")

  const aboutStories = [
    about1,
    about2,
    about3,
    about4,
    about5,
    about6,
    about7,
    about8,
  ]

  return (
    <Layout pageTitle="about">
      <Page>
        {aboutStories.map(story => {
          if (!story) return null

          const { title, subtitle, body, media, ctaLinks, ...rest } = story
          return (
            <AboutContent>
              <AboutText>
                {title ? <AboutTitle>{title}</AboutTitle> : null}
                {subtitle ? <AboutSubtitle>{subtitle}</AboutSubtitle> : null}
                {body ? (
                  <div dangerouslySetInnerHTML={{ __html: body }} />
                ) : null}
                <MultipleLinksSelector links={ctaLinks} />
              </AboutText>

              <ImageContainer>
                <AboutImage
                  src={cloudinaryString(media?.[0].cloudinaryId, 1000)}
                />
              </ImageContainer>
            </AboutContent>
          )
        })}
      </Page>
    </Layout>
  )
}

const Page = styled.section`
  > :nth-child(even) {
    > :nth-child(1) {
      order: 2;
    }
  }
`

const AboutContent = styled.section`
  padding: 2rem 0;
  background-color: ${props =>
    props.theme.primary ? props.theme.primary : "black"};
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`
const AboutText = styled.div`
  padding: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1024px) {
    padding: 2rem;
    padding-right: 4rem;
    width: 50%;
    max-width: 700px;
  }
`

const AboutTitle = styled.h1`
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.75rem;
  text-transform: uppercase;
`

const AboutSubtitle = styled.h1`
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.25rem;
  opacity: 0.5;
  text-transform: uppercase;
`

const AboutDescription = styled.p`
  white-space: pre-wrap;
  font-weight: normal;
`

const ImageContainer = styled.div`
  @media (min-width: 1024px) {
    width: 50%;
    max-width: 700px;
  }
`
const AboutImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
    allGeneral {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
  }
`
